import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import StandardLayout from "./Components/StandardLayout";
import HomePage from "./Pages/HomePage";
import NotFoundPage from "./Pages/NotFountPage";
import UnityDemoPage from "./Pages/UnityDemoPage";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StandardLayout>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/memory-matcher" element={<UnityDemoPage demoName='memory-matcher' version='v0.0.1' portrait/>} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </StandardLayout>
    </ThemeProvider>
  );
}

export default App;

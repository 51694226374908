import { Container, Stack } from "@mui/material";
import { ReactNode } from "react";
import Header from "./Header";

export interface StandardLayoutProps {
    children?: ReactNode
}

const StandardLayout = ({children}: StandardLayoutProps) => <>
    <Header />
    <Container sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent:" center",
        marginTop: "1rem"
    }}>
        <Stack direction="column">
            {children}
        </Stack>
    </Container>
</>;

export default StandardLayout;
import { CSSProperties, useMemo } from "react";
import Unity, { UnityContext } from "react-unity-webgl";

export interface UnityDemoProps {
    demoName: string;
    version: string;
    portrait?: boolean;
}

const portraitStyle : CSSProperties = {
    height: "90vh",
    width: 90*9/16 + "vh"
};
const landscapeStyle : CSSProperties = {
    height: "90vh",
    width: 90*16/9 + "vh"
};

const UnityDemoPage = ({demoName, version, portrait = false, ...rest} : UnityDemoProps) => {

    const context = useMemo(() => {
        return new UnityContext({
            loaderUrl: `builds/${demoName}/${version}/Build.loader.js`,
            dataUrl: `builds/${demoName}/${version}/Build.data`,
            frameworkUrl: `builds/${demoName}/${version}/Build.framework.js`,
            codeUrl: `builds/${demoName}/${version}/Build.wasm`,
            companyName: "Kwyjibo Developments",
            ...rest
        });
    }, [demoName, version])

    const style : CSSProperties = portrait ? portraitStyle : landscapeStyle;

    return <Unity unityContext={context} style={style}/>;
}

export default UnityDemoPage;
import { AppBar, Box, Container, Link, Toolbar, Typography } from "@mui/material";

const Logo = () => <img src="/media/logo.svg" alt="logo" style={{
    width: "3rem",
    height: "3rem",
    marginRight: "1rem"
}} />;

const Header = () => <header>
    <AppBar position="static">
        <Container maxWidth="md">
            <Toolbar disableGutters>
                <Link href="/" color="inherit" underline="none">
                    <Logo />
                </Link>
                <Link href="/" color="inherit" underline="none">
                    <Typography variant="noVirus" component="h1">kwyjibo developments</Typography>
                </Link>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Typography variant="noVirus" component="h3">Demo site</Typography>
            </Toolbar>
        </Container>
    </AppBar>
</header>;

export default Header;
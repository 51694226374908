import { Button, Card, CardActions, CardContent, CardHeader, Collapse, Container, IconButton, IconButtonProps, Stack, styled, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface PlayButtonProps {
    path: string;
}

const PlayButton = ({path} : PlayButtonProps) => {

    const navigator = useNavigate();

    function playCallback() {
        navigator(path);
    }

    return <Button 
        variant="contained"
        onClick={playCallback}
    >Play</Button>;
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }
  
  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

interface DemoElementProps {
    path: string;
    name: string;
    version: string;
    releaseDate: string;
    description: string;
}

const DemoElement = ({path, name, version, releaseDate, description} : DemoElementProps) => {
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{
            maxWidth: {
                sx: "100%",
                md: "30vw"
            }
        }}>
            <CardHeader title={name} subheader={`Version ${version}`}/>
            <CardActions disableSpacing>
                <PlayButton path={path} />
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded}>
                <CardContent>
                    <Typography variant="body1">
                        {description}
                    </Typography>
                    <Typography variant="body2">
                        Release date: {releaseDate}
                    </Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
}

const HomePage = () => <Container>
    <Typography variant="h5">Work in progress games you can play in your browser.</Typography>
    <Stack direction="column">
        <DemoElement 
            path="/memory-matcher"
            name="Memory Matcher"
            version="0.0.1"
            releaseDate="31 March 2022"
            description="Recall positions of hidden cards to identify all of the pairs."
        />
    </Stack>
</Container>;

export default HomePage;
import { Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";


const NotFoundPage = () => {

    const [redirectTime, setRedirectTime] = useState(10);

    useEffect(() => {
        const t = setTimeout(() => {
            setRedirectTime(redirectTime - 1);
        }, 1000);

        return () => {
            // clean up the timer incase it is still running
            clearTimeout(t);
        };
    }, [redirectTime]);

    if (redirectTime <= 0) {
        return <Navigate to="/" />;
    }

    return (<Container
        sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center"
        }}
    >
        <img src="/media/not-found.png" alt="Page not found" />
        <Typography>
            Oops. It seems like you found a page that doesn't exist.
        </Typography>
        <Typography>
            You will be redirected to the <Link to="/">home page</Link> in {redirectTime} seconds.
        </Typography>
    </Container>
    );
}

export default NotFoundPage;
import NoVirusTTF from './fonts/NoVirus.ttf';
import NoVirusOTF from './fonts/NoVirus.otf';
import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
    interface TypographyVariants {
        noVirus: React.CSSProperties;
    }
  
    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        noVirus?: React.CSSProperties;
    }
  }
  
  // Update the Typography's variant prop options
  declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        noVirus: true;
    }
  }

const theme = createTheme({
    palette: {
        primary: {
            main: "#0006D0"
        },
        mode: "light"
    },
    typography: {
        noVirus: {
            fontFamily: 'No-Virus',
        },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'No-Virus';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('No Virus'), url(${NoVirusTTF}) format('truetype'), url(${NoVirusOTF}) format('opentype');
          }
        `,
      },
    },
  });

export default theme;